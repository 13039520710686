import React from "react";
import { Grid, Typography } from "@mui/material";

const Custom404 = () => {
  return (
    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
      <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Typography variant="h1" style={{ color: "#FC6539" }}>
          404
        </Typography>
        <Typography variant="h6">Error - Page Not found</Typography>
      </Grid>
    </Grid>
  );
};

export default Custom404;
